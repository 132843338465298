import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Img from "gatsby-image"
import * as FooterStyles from "./footer.module.css"

const date = new Date()
const year = date.getFullYear()

export default function Footer() {
    const data = useStaticQuery(graphql`
        query {
            bacp: file(relativePath: {eq: "images/bacp.png"}) {
                childImageSharp {
                    fixed(width: 224, quality: 72) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
            otc: file(relativePath: {eq: "images/otc.png"}) {
                childImageSharp {
                    fixed(width: 100, quality: 72) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
            acto: file(relativePath: {eq: "images/acto.png"}) {
                childImageSharp {
                    fixed(width: 112, quality: 72) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
    `)

    return (
        <footer>
            <div className={FooterStyles.container}>
                <div className={FooterStyles.links}>
                    <h4>Links</h4>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/appointments/">Appointments</Link></li>
                        <li><Link to="/therapy-your-way/">Therapy Your Way</Link></li>
                        <li><Link to="/about/">About</Link></li>
                        <li><Link to="/services/">Services</Link></li>
                        <li><Link to="/disordered-eating-and-eating-disorders/">Eating Disorders</Link></li>
                        <li><Link to="/faq/">FAQ</Link></li>
                        <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
                    </ul>
                </div>
                <div className={FooterStyles.accreditations}>
                    <Img fixed={data.acto.childImageSharp.fixed} alt="ACTO accreditation logo" />
                    <Img fixed={data.otc.childImageSharp.fixed} alt="OTC accreditation logo" />
                    <Img fixed={data.bacp.childImageSharp.fixed} alt="New BACP accreditation logo" />
                </div>
                <p className={FooterStyles.credit}>Website by <a href="https://goddard.digital" target="_blank">Goddard Digital</a></p>
                <p className={FooterStyles.copyright}>{year} © - Paula Abbey Counselling & Psychotherapy</p>
                </div>
        </footer>
    )
}