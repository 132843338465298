import React from "react"
import "./globalStyles.css"
import "fontsource-lato/900.css"
import "fontsource-lato/700.css"
import "fontsource-lato/400.css"
import "fontsource-lato/300.css"
import "fontsource-lato/100.css"
import Header from "./header/header"
import Footer from "./footer/footer"

export default function Layout({children}) {
    return (
        <>
            <Header />
            <main>{children}</main>
            <Footer />
        </>
    )
}