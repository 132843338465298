import React, { useState } from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import * as HeaderStyles from "./header.module.css"

export default function Header() {
    const [navOpen, setNavOpen] = useState(false)

    return (
        <header>
            <div className={HeaderStyles.contactBar}>
                <div className={HeaderStyles.contactDetails}>
                    <a className={HeaderStyles.email} href="mailto:hello@paulaabbey.co.uk"><FontAwesomeIcon icon={faEnvelope} />hello@paulaabbey.co.uk</a>
                    <a className={HeaderStyles.tel} href="tel:07898437229"><FontAwesomeIcon icon={faPhone} />07898 437 229</a>
                </div>
            </div>
            <div className={HeaderStyles.navBar}>
                <div className={HeaderStyles.navContainer}>
                    <Link className={HeaderStyles.logo} to="/">
                        <StaticQuery
                            query={graphql`
                                query {
                                    file(relativePath: {eq: "images/logo.png"}) {
                                        childImageSharp {
                                            fixed(width: 220, quality: 72) {
                                                ...GatsbyImageSharpFixed_withWebp_noBase64
                                            }
                                        }
                                    }
                                }
                            `}
                            render={data => (
                                <Img fixed={data.file.childImageSharp.fixed} alt="Logo" />
                            )}
                        />
                    </Link>
                    <div className={HeaderStyles.toggle} navopen={navOpen} onClick={() => setNavOpen(!navOpen)} onKeyPress={() => setNavOpen(!navOpen)} aria-label="Main menu" role="button" tabIndex={0}>
                        <div className={navOpen ? `${HeaderStyles.hamburgerOpen}` : `${HeaderStyles.hamburger}`}></div>
                    </div>
                    <nav style={{right: navOpen ? "0" : "-100%"}}>
                        <Link activeStyle={{color: "var(--secondary-800)"}} to="/">Home</Link>
                        <Link activeStyle={{color: "var(--secondary-800)"}} to="/about/">About</Link>
                        <Link activeStyle={{color: "var(--secondary-800)"}} to="/therapy-your-way/">Therapy Your Way</Link>
                        <Link activeStyle={{color: "var(--secondary-800)"}} to="/services/">Services</Link>
                        <Link activeStyle={{color: "var(--secondary-800)"}} to="/faq/">FAQ</Link>
                        <Link activeStyle={{color: "var(--secondary-800)"}} to="/appointments/">Appointments</Link>
                    </nav>
                </div>
            </div>
        </header>
    )
}